import React from "react"
//import { Link } from "gatsby"
//import { HiArrowNarrowLeft} from "react-icons/hi";


import Layout from "../components/layout"
import SEO from "../components/seo"
import BackLink from "../components/backlink"

const Contact = () => (
  <Layout>
    <SEO title="Contact Us" />
    <BackLink/>
        <h1>Contact</h1>
        <address>
            Mailing Adress: 300 Millburn Ave, #262, Millburn, NJ 07041<br />
            Phone: (800)-218-0530 <br />
        </address>
        <h2>Contact Form</h2>
        <p>Send us a message any time.</p>
    <form name="contact" method="POST" data-netlify="true">
        <label>
            Name
            <input type="text" name="name" id="name" />
        </label>
        <label>
            Email
            <input type="email" name="email" id="email" />
        </label>
        <label>
            Subject
            <input type="text" name="subject" id="subject" />
        </label>
        <label>
            Message
            <textarea name="message" id="message" rows="5" />
        </label>
        <button type="submit">Send</button>
        <input type="reset" value="Clear" />
        
        <input type="hidden" name="form-name" value="contact" />
        </form>
        <BackLink/>
  </Layout>
)

export default Contact
